<template>
  <div class="filter-stade" :class="{ active: editing }">
    <div v-if="editing">
      <v-popover
        ref="popover"
        popoverClass="popover-stade"
        :placement="placement"
        offset="10px"
        :handleResize="true"
      >
        <template slot="popover">
          <div class="stade">
            <b-form-group label="Statut :">
              <multiselect
                v-model="selectedStatus"
                :options="optionsStatus"
                placeholder="Rechercher statut"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                label="text"
                track-by="value"
                :preselect-first="false"
              >
                <span slot="noResult">Aucun statut trouvé.</span>
                <span slot="noOptions">Aucun statut trouvé.</span>
              </multiselect>
            </b-form-group>
            <b-form-group label="Stade :">
              <multiselect
                v-model="selectedStage"
                :options="stageOptions"
                placeholder="Rechercher stade"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                label="text"
                track-by="value"
                :preselect-first="false"
              >
                <span slot="noResult">Aucun stade trouvé.</span>
                <span slot="noOptions">Aucun stade trouvé.</span>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Sous-stade"
              v-if="selectedStage && subStageOptions && subStageOptions.length"
            >
              <multiselect
                v-model="selectedSubStage"
                :options="subStageOptions"
                placeholder="Rechercher sous-stade"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                label="text"
                track-by="value"
                :preselect-first="false"
                group-values="substage"
                group-label="stage"
                :group-select="true"
              >
                <span slot="noResult">Aucun sous-stade trouvé.</span>
                <span slot="noOptions">Aucun sous-stade trouvé.</span>
              </multiselect>
            </b-form-group>
          </div>
          <div class="action">
            <b-button
              variant="outline-secondary"
              size="sm"
              @click="hundlePopoverCancel()"
            >
              Annuler
            </b-button>
            <b-button variant="success" size="sm" @click="hundlePopoverOk()">
              Enregistrer
            </b-button>
          </div>
        </template>
      </v-popover>
    </div>
    <div
      @click.prevent.stop="editing = true"
      class="icon"
      @click="ouvert"
      :class="{ iconrefactor: styleFilterHiddenCol }"
    >
      <font-awesome-icon class="filter-icon" :icon="['fas', 'filter']" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
// import { mapActions, mapGetters } from 'vuex'

Vue.component('v-popover', VPopover)

export default {
  props: {
    placement: {
      type: String,
      default: 'auto'
    },
    stageOptions: {
      default: () => []
    },
    styleFilterHiddenCol: { required: false }
  },
  data() {
    return {
      selectedStatus: [],
      selectedStage: [],
      selectedSubStage: [],
      editing: false,
      optionsStatus: [
        { text: 'Ouvert', value: 'open' },
        { text: 'Conclu', value: 'won' },
        { text: 'Perdu', value: 'lost' }
      ]
    }
  },
  methods: {
    hundlePopoverCancel() {
      this.$refs['popover'].hide()
      this.selectedStatus = []
      this.selectedStage = []
      this.selectedSubStage = []
      this.editing = false
    },

    async hundlePopoverOk() {
      let payload = []
      if (this.selectedStatus && this.selectedStatus.length) {
        payload.push({
          glue:
            this.selectedStatus && this.selectedStatus.length > 1
              ? 'or'
              : 'and',
          key: 'status',
          value: this.selectedStatus.map(id => {
            return id.value
          }),
          operateur: '=',
          keyLabel: 'Statut',
          valueLabel: this.selectedStatus
            .map(Label => {
              return Label.text
            })
            .join(' , ')
        })
      } else {
        payload.push({
          key: 'status',
          value: null,
          operateur: '='
        })
      }
      if (
        this.selectedStage &&
        this.selectedStage.length &&
        !this.selectedSubStage.length
      ) {
        let label = this.selectedStage
        this.stageOptions.forEach(ss => {
          if (ss.value == this.selectedStage) {
            label = ss.text
          }
        })
        payload.push({
          glue:
            this.selectedStage && this.selectedStage.length > 1 ? 'or' : 'and',
          key: 'stage',
          value: this.selectedStage.map(id => {
            return id.value
          }),
          operateur: '=',
          keyLabel: 'Stade',
          valueLabel: label
            .map(Label => {
              return Label.text
            })
            .join(' , '),
          keyField: 'stade'
        })
      } else {
        payload.push({
          key: 'stage',
          value: null,
          operateur: '='
        })
      }
      if (this.selectedSubStage && this.selectedSubStage.length) {
        let label = this.selectedSubStage
        this.subStageOptions.forEach(ss => {
          if (ss.value == this.selectedSubStage) {
            label = ss.text
          }
        })
        payload.push({
          glue:
            this.selectedSubStage && this.selectedSubStage.length > 1
              ? 'or'
              : 'and',
          key: 'sub_stage',
          value: this.selectedSubStage.map(id => {
            return id.value
          }),
          operateur: '=',
          keyLabel: 'Sous-stade',
          valueLabel: label
            .map(Label => {
              return Label.text
            })
            .join(' , '),
          keyField: 'sous_stade'
        })
      } else {
        payload.push({
          key: 'sub_stage',
          value: null,
          operateur: '='
        })
      }

      if (
        !this.selectedStatus &&
        this.selectedStage &&
        this.selectedStage.length &&
        this.selectedSubStage &&
        this.selectedSubStage.length
      ) {
        payload = payload.filter(p => {
          return p.key == 'sub_stage'
        })
      }
      if (
        this.selectedStatus &&
        this.selectedStatus.length &&
        this.selectedStage &&
        this.selectedStage.length &&
        !this.selectedSubStage.length
      ) {
        payload = payload.map(p => {
          if (p.key == 'status') {
            if (p.value && p.value.length > 1) {
              if (p.value.includes('won') == false) {
                return {
                  glue: 'and',
                  key: 'status',
                  value: ['won'],
                  operateur: '<>',
                  keyLabel: 'Statut',
                  valueLabel: this.selectedStatus
                    .map(Label => {
                      return Label.text
                    })
                    .join(' , ')
                }
              } else if (p.value.includes('lost') == false) {
                return {
                  glue: 'and',
                  key: 'status',
                  value: ['lost'],
                  operateur: '<>',
                  keyLabel: 'Statut',
                  valueLabel: this.selectedStatus
                    .map(Label => {
                      return Label.text
                    })
                    .join(' , ')
                }
              } else if (p.value.includes('open') == false) {
                return {
                  glue: 'and',
                  key: 'status',
                  value: ['open'],
                  operateur: '<>',
                  keyLabel: 'Statut',
                  valueLabel: this.selectedStatus
                    .map(Label => {
                      return Label.text
                    })
                    .join(' , ')
                }
              }
            } else {
              return {
                glue: 'and',
                key: 'status',
                value: this.selectedStatus.map(id => {
                  return id.value
                }),
                operateur: '=',
                keyLabel: 'Statut',
                valueLabel: this.selectedStatus
                  .map(Label => {
                    return Label.text
                  })
                  .join(' , ')
              }
            }
          }
          return p
        })
      }
      if (
        this.selectedStatus &&
        this.selectedStatus.length &&
        this.selectedStage &&
        this.selectedStage.length &&
        this.selectedSubStage &&
        this.selectedSubStage.length
      ) {
        payload = payload.map(p => {
          if (p.key == 'status') {
            if (p.value && p.value.length > 1) {
              if (p.value.includes('won') == false) {
                return {
                  glue: 'and',
                  key: 'status',
                  value: ['won'],
                  operateur: '<>',
                  keyLabel: 'Statut',
                  valueLabel: this.selectedStatus
                    .map(Label => {
                      return Label.text
                    })
                    .join(' , ')
                }
              } else if (p.value.includes('lost') == false) {
                return {
                  glue: 'and',
                  key: 'status',
                  value: ['lost'],
                  operateur: '<>',
                  keyLabel: 'Statut',
                  valueLabel: this.selectedStatus
                    .map(Label => {
                      return Label.text
                    })
                    .join(' , ')
                }
              } else if (p.value.includes('open') == false) {
                return {
                  glue: 'and',
                  key: 'status',
                  value: ['open'],
                  operateur: '<>',
                  keyLabel: 'Statut',
                  valueLabel: this.selectedStatus
                    .map(Label => {
                      return Label.text
                    })
                    .join(' , ')
                }
              }
            } else {
              return {
                glue: 'and',
                key: 'status',
                value: this.selectedStatus.map(id => {
                  return id.value
                }),
                operateur: '=',
                keyLabel: 'Statut',
                valueLabel: this.selectedStatus
                  .map(Label => {
                    return Label.text
                  })
                  .join(' , ')
              }
            }
          }
          return p
        })
      }
      this.$emit('change', payload)
      this.hundlePopoverCancel()
    },
    ouvert() {
      this.$nextTick(() => {
        this.$refs['popover'].show()
      })
    }
  },
  computed: {
    subStageOptions: function() {
      return this.selectedStage.map(stage => {
        return {
          stage: stage.text,
          substage: stage.subStages.map(ss => {
            return {
              text: ss.name,
              value: ss.id
            }
          })
        }
      })
    }
  },
  watch: {
    editing(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs['popover'].show()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-stade {
    opacity: 1;
    display: block !important;
    z-index: 2000;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 20px 0 0;
      text-align: left;
      max-width: unset;
      width: 300px;

      .close-tooltip {
        position: absolute;
        top: 3px;
        right: 8px;
        color: #656565;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .stade {
        .form-group {
          margin: 0 16px 10px;
        }
      }

      .stages {
        margin: 0 16px 10px;
        .label {
          padding-bottom: calc(0.375rem + 1px);
          margin-bottom: 0;
          font-size: inherit;
          line-height: 1.5;
          color: #656565;
        }

        .options {
          display: table;
          width: 100%;
          overflow: hidden;
          margin-bottom: 4px;
          border-radius: 4px;
          table-layout: fixed;
          .stage {
            height: 28px;
            cursor: pointer;
            display: table-cell;
            text-align: center;
            padding: 0;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            background: #08a742;
            position: relative;

            &:before {
              width: 20px;
              height: 24px;
              position: absolute;
              right: -11px;
              top: 2px;
              border-top: 3px solid #fff;
              border-right: 3px solid #fff;
              transform: scaleX(0.3) rotate(45deg);
              content: ' ';
              background: #08a742;
              cursor: pointer;
              z-index: 1;
            }

            input {
              width: auto;
              position: absolute;
              opacity: 0;
              cursor: pointer;
              border: none;
              padding: 0;
              box-shadow: none;
              margin-right: 3px;
            }

            &.active {
              background: #08a742;
            }
            &.active ~ label {
              background: #e5e5e5;
            }

            &.active ~ label:before {
              background: #e5e5e5;
            }
          }
        }
      }
      .action {
        margin: 0;
        padding: 8px 16px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #f7f7f7;
        button {
          &:first-child {
            margin-right: 4px;
          }
        }
      }
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;

      border-color: black !important;
      background: white !important;
      border-width: 1px !important;
      border-left-color: rgba(0, 0, 0, 0.2) !important;
      border-right-color: rgba(0, 0, 0, 0.2) !important;
      border-top-color: rgba(0, 0, 0, 0.2) !important;
      border-bottom-color: rgba(0, 0, 0, 0.2) !important;

      width: 10px;
      height: 10px;
      z-index: -2;
      transform: rotateZ(45deg);
    }

    &[x-placement^='top'] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;

      .tooltip-arrow {
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='right'] {
      margin-left: 65px;
      .tooltip-arrow {
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^='left'] {
      margin-right: 5px;

      .tooltip-arrow {
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;
      max-width: unset;
      border-radius: 0.1rem;
      .popover-inner {
        background: $color;
        color: black;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
  .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.5;
  }
}
.filter-stade {
  &.active {
    margin: -9px;
  }
  .icon {
    width: 16px;
    // border: 1px solid #d8d8d8;
    position: absolute;
    left: 75%;
    right: 0%;
    bottom: 15px;
    top: 23px;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      background-color: transparent;
    }
    &.iconrefactor {
      top: 15px;
    }
    .filter-icon {
      color: #c5c5c5;
      font-size: 13px;
      &:hover {
        background-color: transparent;
        color: #5e5e5e;
      }
    }
  }
}
</style>
